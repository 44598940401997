import {tns} from '../../../../node_modules/tiny-slider/src/tiny-slider.js';
import '../../../../node_modules/tiny-slider/src/tiny-slider.scss';

var items = false;
var container = '';

if (items = $('.slider-chalet .slider').data('items')) {
    container = '.slider-chalet';
    initTinySlider(items, container);
}else if (items = $('.slider-accueil .slider').data('items')) {
    container = '.slider-accueil';
    initTinySlider(items, container);
}

function initTinySlider(items, container){
    var slider = tns({
        container: container + ' .slider',
        items: items,
        slideBy: 'page',
        autoplay: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        responsive: {
            300: {
                edgePadding: 20,
                gutter: 0,
                items: 1
            },
            640: {
                edgePadding: 20,
                gutter: 20,
                items: 2
            },
            700: {
                gutter: 30
            },
            900: {
                gutter: 10,
                items: items
            }
        }
    });
}