/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
const $ = require('jquery');
global.$ = global.jQuery = $;

import { Modal, Carousel } from 'bootstrap';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import Alert from 'bootstrap/js/dist/alert';

import 'aos/dist/aos.css';
import './styles/includes/css/cookieconsent.css';
import Lightbox from './styles/includes/js/lightbox';

import { tns } from '../node_modules/tiny-slider/src/tiny-slider';
import '../node_modules/tiny-slider/src/tiny-slider.scss';
import './styles/includes/js/init-tiny-slider';

setTimeout(() => {
    $('.lightbox.imgs a').each(function () {
        $(this).click(function () {
            const lightbox = new Lightbox($(this), Modal, Carousel);
            return false;
        });
    })
}, 1000);

// import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.min.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js';

// $(document).ready(function() {
//     // you may need to change this code if you are not using Bootstrap Datepicker
//     $('.js-datepicker').datepicker({
//         format: 'dd-mm-yyyy'
//     });
// });


import Popup from './styles/includes/js/popup';
const popup = new Popup(Modal)

import './styles/includes/js/stickymenu';

import './styles/includes/js/customSelect';

import AddSelection from './styles/includes/js/addSelection';

$('#add-selection').on('click',function(){
    const addSelectionl = new AddSelection('#add-selection', Modal);
    return false;
})

// $('.row-annonces .add-selection-item').each(function(){
//     $(this).on('click',function(){
//         let el = $(this).attr('id');
//         const addSelectionl = new AddSelection('#' + el, Modal);
//         return false;
//     })
// });

// $('.row-annonces .del-selection-item').each(function(){
//     $(this).on('click',function(){
//         let el = $(this).attr('id');
//         const addSelectionl = new AddSelection('#' + el, Modal);
//         return false;
//     })
// });

var isMobile = false; //initiate as false
let wW = window.innerWidth;
let Wh = window.innerHeight;
let wRatio = wW / Wh;
// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isMobile = true;
}

if (wRatio > 1 && !isMobile) {
    $('#search').css('height', window.innerHeight);
    $('.search').css('height', window.innerHeight - 260);
}



if ($('#affichageForm input#affichage').attr('value') == 'inline') {
    $('#affichageForm div button').each(function(){
        $(this).removeClass('active')
    })
    $('#affichageForm .btn.affichage-inline').addClass('active')
    
} else if($('#affichageForm input#affichage').attr('value') == 'block') {
    $('#affichageForm div button').each(function(){
        $(this).removeClass('active')
    })
    $('#affichageForm .btn.affichage-block').addClass('active')
} else  {
    $('#affichageForm div button').each(function(){
        $(this).removeClass('active')
    })
    $('#affichageForm .btn.affichage-map').addClass('active')
}

$('.affichage-inline').on('click', function(){
    $('#affichageForm input#affichage').attr('value', 'inline');
    document.forms['affichageForm'].submit();
    return false;
})

$('.affichage-block').on('click', function(){
    $('#affichageForm input#affichage').attr('value', 'block');
    document.forms['affichageForm'].submit();
    return false;
})

$('.affichage-map').on('click', function(){
    $('#affichageForm').attr('action', '/catalogue/geo');
    //$('#affichageForm input#affichage').attr('value', 'map');
    document.forms['affichageForm'].submit();
    return false;
})

if ($('#triForm input#triPrix').attr('value') == 'asc') {
    $('#triForm .btn.tri-prix').html('Prix <i class="bi bi-caret-up-fill"></i>');
}
else{
    $('#triForm .btn.tri-prix').html('Prix <i class="bi bi-caret-down-fill"></i>');
}
$('.tri-prix').on('click', function(){
    if ($('#triForm input#triPrix').attr('value') == 'asc') {
        $('#triForm input#triPrix').attr('value', 'desc');
        $('#triForm btn.tri-prix').html('Prix <i class="bi bi-caret-up-fill"></i>');
    }
    else{
        $('#triForm input#triPrix').attr('value', 'asc');
        $('#triForm btn.tri-prix').html('Prix <i class="bi bi-caret-down-fill"></i>');
    }
    document.forms['triForm'].submit();
    return false;
})
    
let valLimit = $('#currentLimit').attr('value');
$('#limitForm #limit').val(valLimit);
$('#limitForm #limit').on('change', function() {
    document.forms['limitForm'].submit();
});

$('#searchForm .nav-categories .collapse .card a').each(function(){
    $(this).on('click', function(){
        $('#searchForm #categorie').val($(this).data('id'));
        $('#searchForm #categorieName').val($(this).data('name'));
        setTimeout(function(){ $("#searchForm").submit(); }, 1000);
    })
})

const simpleParallax = require('simple-parallax-js');
let image = document.getElementsByClassName('img-parallax');
new simpleParallax(image, {
    delay: .6,
    transition: 'cubic-bezier(0,0,0,1)',
    scale: 1.5,
    orientation: 'up right'
});

let sliderAccueilQuery = get_query();
console.log('Situation ' + sliderAccueilQuery[3])
if (3 in sliderAccueilQuery && sliderAccueilQuery[3] == 'notre-location-de-vacances-a-pia' ) {

    var slider = tns({
        container: '.slider-situation .slider',
        items: 3,
        slideBy: 'page',
        autoplay: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        responsive: {
            300: {
                edgePadding: 20,
                gutter: 0,
                items: 1
            },
            640: {
                edgePadding: 20,
                gutter: 20,
                items: 2
            },
            700: {
                gutter: 30
            },
            900: {
                gutter: 10,
                items: 3
            }
        }
    });
}

setTimeout(() => {
    $('.slider-situation a').each(function () {
        $(this).click(function () {
            const lightboxAccueil = new Lightbox($(this), Modal, Carousel, 'slider');
            return false;
        });
    })
}, 1000);

function get_query() {
    var url = document.location.href;
    var qs = url.split('\/');
    console.log(qs);
    return qs;
}



