class AddSelection {
    constructor(el, Modal) {
        this.el = $(el);
        this.id = this.el.data('id');
        this.modal = Modal;

        let url = this.el.data('href');
        this.addSelection(url, this.modal);
    }

    addSelection(url, Modal){
        $.ajax({
            type: 'POST',
            url: url,
            async: false,
            success: function (res) {
                
                if(res.sent == 'add' ){
                    //console.log(res);
                    let htmlModal = '<div id="myModal" class="modal fade" tabindex="-1">'
                    htmlModal += '<div class="modal-dialog modal-dialog-centered">'
                    htmlModal += '    <div class="modal-content">'
                    htmlModal += '    <div class="modal-header">'
                    htmlModal += '        <h5 class="modal-title">Ma sélection</h5>'
                    htmlModal += '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>'
                    htmlModal += '    </div>'
                    htmlModal += '    <div class="modal-body">'
                    htmlModal += '        <p>Vous avez ajouté la référence ' + res.ref + ' à votre sélection</p>'
                    htmlModal += '    </div>'
                    htmlModal += '    <div class="modal-footer">'
                    htmlModal += '        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>'
                    htmlModal += '    </div>'
                    htmlModal += '    </div>'
                    htmlModal += '</div>'
                    htmlModal += '</div>'

                    $('footer').append(htmlModal);
            
                    var myModal = new Modal($('#myModal'), {
                        keyboard: false
                    })

                    myModal.show();

                    $('#ma-selection i').html('<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="font-size:0.5rem;">' + res.total + '</span>');
                    
                } 

                else if(res.sent == 'del' ){
                    //console.log(res);
                    let htmlModal = '<div id="myModal" class="modal fade" tabindex="-1">'
                    htmlModal += '<div class="modal-dialog modal-dialog-centered">'
                    htmlModal += '    <div class="modal-content">'
                    htmlModal += '    <div class="modal-header">'
                    htmlModal += '        <h5 class="modal-title">Ma sélection</h5>'
                    htmlModal += '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>'
                    htmlModal += '    </div>'
                    htmlModal += '    <div class="modal-body">'
                    htmlModal += '        <p>Vous avez supprimé la référence ' + res.ref + ' à votre sélection</p>'
                    htmlModal += '    </div>'
                    htmlModal += '    <div class="modal-footer">'
                    htmlModal += '        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>'
                    htmlModal += '    </div>'
                    htmlModal += '    </div>'
                    htmlModal += '</div>'
                    htmlModal += '</div>'

                    $('footer').append(htmlModal);
            
                    var myModal = new Modal($('#myModal'), {
                        keyboard: false
                    })

                    myModal.show();
                    console.log(res.redirect);
                    setTimeout(function(){ 
                        window.location.replace(res.redirect);
                    }, 2000);
                    
                    
                } 
            },
            error: function (err) {
                alert("An error occurred while sorting. Please refresh the page and try again.")
            }
        });
    }

}

export default AddSelection;